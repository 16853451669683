import { createContext, useCallback, useEffect, useState } from "react";
import { AUTH_TOKEN } from "services/AuthService";
import { getUserByIdService } from "services/UserService";

export const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
  const [credentials, setCredentials] = useState(null);

  const savedCredentials = (value) => {
    setCredentials(value);
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(value));
  };

  const getCredentials = useCallback(async () => {
    const credentialsStorage = localStorage.getItem(AUTH_TOKEN);
    if (!credentialsStorage) return;
    const localCredential = JSON.parse(credentialsStorage)
    getUserByIdService(localCredential.id, (data) => {
      savedCredentials(data)
    })
  }, []);

  const removedCrendentials = () => {
    localStorage.removeItem(AUTH_TOKEN);
    setCredentials(null);
  };

  useEffect(() => {
    if (credentials) return;
    getCredentials();
  }, [credentials, getCredentials]);

  return (
    <AuthContext.Provider
      value={{
        credentials,
        savedCredentials,
        getCredentials,
        removedCrendentials,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
