import {
  setDoc,
  updateDoc,
  doc,
  deleteDoc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { firestore } from "../configs/FirebaseConfig";
import { message } from "antd";

export const USER = "user";
export const ADMIN = "admin";

export const getUserByIdService = async (id, callback) => {
  try {
    const userResponse = await getDoc(doc(firestore, USER, id));
    if (userResponse.exists()) {
      callback(userResponse.data());
      return;
    }

    const adminResponse = await getDoc(doc(firestore, ADMIN, id));
    if (adminResponse.exists()) {
      callback(adminResponse.data());
      return;
    }
  } catch (error) {
    message.error(`Error al obtener el usuario`);
  }
};

export const createUserService = (data, callback, path = USER) => {
  setDoc(doc(firestore, path, data.id), data)
    .then(async (response) => {
      callback(response);
    })
    .catch((error) => {
      message.error(`Error al crear el registro`);
    });
};

export const updateUserService = (data, callback, path = USER) => {
  updateDoc(doc(firestore, path, data.id), data)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      // message.error(`Error al actualizar el usuario`);
    });
};

export const deleteUserService = async (data, path = USER) => {
  deleteDoc(doc(firestore, path, data.id)).catch((error) => {
    message.error(`Error al eliminar el usuario`);
  });
};

export const getAdminTokens = async () => {
  const notificationCollection = collection(firestore, "admin");

  const querySnapshot = await getDocs(notificationCollection);
  const list = querySnapshot.docs.map((doc) => doc.data().token);
  return list?.flat().filter(item => item)
};

export const getAdminFullname = async () => {
  const notificationCollection = collection(firestore, "admin");

  const querySnapshot = await getDocs(notificationCollection);
  return querySnapshot.docs.map((doc) => doc.data().fullname);
};


export const usersRegister = async () => {
  const userQuery = query(
    collection(firestore, 'user'),
    orderBy("company", "desc")
  )
  const querySnapshot = await getDocs(userQuery)
  return querySnapshot.docs.map((doc) => doc.data())
}
