import {
  AppstoreAddOutlined,
  DashboardOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "dashboards.service-request",
        path: `${APP_PREFIX_PATH}/dashboards/service-request/list`,
        title: "sidenav.apps.dashboard.request",
        icon: InboxOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "dashboards.service-budget",
        path: `${APP_PREFIX_PATH}/dashboards/service-budget/list`,
        title: "sidenav.apps.dashboard.budget",
        icon: AppstoreAddOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
