import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCJQ1XTLwuJZIBdOh3ssh04rIuoeyPGeWo",
  authDomain: "test-nhc.firebaseapp.com",
  projectId: "test-nhc",
  storageBucket: "test-nhc.appspot.com",
  messagingSenderId: "834475497025",
  appId: "1:834475497025:web:0a03049768919a272298c0",
  measurementId: "G-C47TSFJK7W",
};

const app = initializeApp(firebaseConfig);
// firebase utils
const firestore = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export { app, firestore, storage, analytics, messaging };
