import { message } from "antd";

import FirebaseService from "services/FirebaseService";
import { createUserService, getUserByIdService, USER } from "./UserService";

export const AUTH_TOKEN = "USER_AUTH_TOKEN";

export const signInWithEmail = async (values, callback) => {
  const { email, password } = values;
  try {
    const response = await FirebaseService.signInEmailRequest(email, password);
    if (response.message) {
      message.success(response.message);
      callback(false);
    } else {
      getUserByIdService(response.user.uid, (data) => {
        callback({ ...data });
      });
    }
  } catch (err) {
    callback(false);
    message.error(err);
  }
};

export const signOut = async (callback = () => {}) => {
  try {
    await FirebaseService.signOutRequest();
    callback();
  } catch (err) {
    message.error(err);
  }
};

export const signUpWithEmail = async (values, callback) => {
  const { email, password } = values;
  try {
    const userResponse = await FirebaseService.signUpEmailRequest(
      email,
      password
    );
    if (userResponse.message) {
      message.success(userResponse.message);
      callback(false);
    } else {
      createUserService(
        {
          id: userResponse.user.uid,
          company: values.company,
          email: values.email,
          fullname: values.fullname,
          usertype: values.usertype,
        },
        () => {
          callback({
            id: userResponse.user.uid,
            company: values.company,
            email: values.email,
            fullname: values.fullname,
            usertype: values.usertype,
          });
        },
        USER
      );
    }
  } catch (error) {
    callback(false);
    message.error(error);
  }
};
