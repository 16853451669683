import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { firestore } from "../configs/FirebaseConfig";

export const NOTIFICATION = "notification";

export const createNotificationService = (data) => {
  addDoc(collection(firestore, NOTIFICATION), data).catch((error) => {
    console.log("notification: ", error);
  });
};

export const deleteNotificationsService = (notifications) => {
  notifications.forEach(async element => {
    await deleteDoc(doc(firestore, NOTIFICATION, element.id))
  });
};
