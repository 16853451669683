import React, { lazy, Suspense, useEffect, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { AuthContext } from "context/authContext";
import { onMessagingToken } from "services/FirebaseMessaging";
import { ADMIN, updateUserService, USER } from "services/UserService";

export const AppViews = () => {
  const { credentials, savedCredentials } = useContext(AuthContext);

  useEffect(() => {
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          onMessagingToken().then((responseToken) => {
            if (!responseToken) return;
            const filterToken = credentials.token ? credentials.token.filter(
              (item) => item !== responseToken
            ) : [];
            updateUserService(
              {
                ...credentials,
                token: [...filterToken, responseToken],
              },
              () => {
                if (credentials) return;
                savedCredentials({
                  ...credentials,
                  token: [...filterToken, responseToken],
                });
              },
              credentials.usertype === "admin" ? ADMIN : USER
            );
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, [credentials, savedCredentials]);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboards`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
