import React, { useEffect } from "react";
import { notification } from "antd";
import { Provider } from "react-redux";
import { onMessage } from "firebase/messaging";
import { messaging } from "configs/FirebaseConfig";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import { AuthContextProvider } from "context/authContext";
import { SmileOutlined } from "@ant-design/icons";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  useEffect(() => {
    onMessage(messaging, (message) => {
      notification.open({
        message: "Hey amigo, lee esto!",
        description: message.notification.body,
        placement: "topRight",
        duration: 5,
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
    });
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <AuthContextProvider>
            <Router>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </Router>
          </AuthContextProvider>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
