import { getToken } from "firebase/messaging";
import { messaging } from "configs/FirebaseConfig";
import axios from "axios";
import { createNotificationService } from "./NotificationService";

export const onMessagingToken = async () => {
  const token = await getToken(messaging, {
    vapidKey:
      "BPrUb9unwDVIg9MeXr7NN42nJqVthEn23E8dPI_btaqOAyd78pV3InlAJaW6YSoOwls1mN0xgStVpbIjK7iQHmk",
  });
  if (!token) return null;
  return token;
};

export const sendNotification = async ({ user, body, tokens }) => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const [, , day, year, hour] = date.toString().split(" ");
  createNotificationService({
    body,
    useremail: user.email,
    username: user.fullname,
    time: `${day}/${month}/${year} ${hour}`,
  });

  tokens.forEach((token) => {
    axios
      .post(
        "https://fcm.googleapis.com/fcm/send",
        {
          notification: {
            body,
            icon: "/notificaciones/images/user-icon.png",
          },
          to: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `key=AAAAwkqdlkE:APA91bFN7jKZpOPsw1kwi3laVG_EFRRvSHBOjLB3rfvCASSPLmi8RWw1yaf6EzHaIYlrohM0hDA73Q0VgSZ4Nab1Blnn2INsxpt9Sh31Sh5cLh3czOXxpEM8sfBpShOsAVt36c2XaYjQ`,
          },
        }
      )
      .catch((error) => {
        console.log({ error });
      });
  });

};
