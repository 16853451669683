import React, { useContext } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import { signOut } from "services/AuthService";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import { useHistory } from "react-router-dom";
import { AuthContext } from "context/authContext";
import { blue } from "@ant-design/colors";

const menuItem = [
  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/app/pages/setting/edit-profile",
  },
];

export const NavProfile = () => {
  const history = useHistory();
  const { removedCrendentials, credentials } = useContext(AuthContext);
  const profileImg = (
    <Avatar style={{ color: blue.primary }}>
      {credentials.fullname.toUpperCase().slice(0, 2)}
    </Avatar>
  );
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {profileImg}
          <div className="pl-3">
            <h4 className="mb-0">{credentials.fullname}</h4>
            <span className="text-muted">
              {credentials.usertype.toUpperCase()}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) =>
              signOut(() => {
                removedCrendentials();
                history.push(AUTH_PREFIX_PATH);
              })
            }
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Cerrar sesión</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>{profileImg}</Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
