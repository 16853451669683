import React, { useState, useContext, useCallback, useEffect } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import { MailOutlined, BellOutlined } from "@ant-design/icons";
import { firestore } from "configs/FirebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import Flex from "components/shared-components/Flex";
import { AuthContext } from "context/authContext";
import { NOTIFICATION, deleteNotificationsService } from "services/NotificationService";

const getNotificationBody = (list) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            <div className="pr-3">
              <Avatar
                className={`ant-avatar-${item.type}`}
                icon={<MailOutlined />}
              />
            </div>
            <div className="mr-3">
              <span className="text-gray-light">{item.body}</span>
            </div>
            <small className="ml-auto">{item.time}</small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">No tienes nuevas notificaciones</p>
    </div>
  );
};

export const NavNotification = () => {
  const { credentials } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const removeNotifications = () => {
    deleteNotificationsService(data)
    setData([])
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notificaciones</h4>
        <Button type="link" onClick={removeNotifications} size="small">
          Limpiar{" "}
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data)}</div>
    </div>
  );

  const getNotificationService = useCallback(async () => {
    const notificationCollection = collection(firestore, NOTIFICATION);
    const notificationQuery = query(
      notificationCollection,
      where("useremail", "==", credentials.email)
    );

    const querySnapshot = await getDocs(notificationQuery);
    querySnapshot.forEach((doc) => {
      setData((oldState) => [...oldState, { ...doc.data(), id: doc.id }]);
    });
  }, [credentials.email]);

  useEffect(() => {
    getNotificationService();
  }, [getNotificationService]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={data.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
